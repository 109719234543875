<template>
  <div class="backhome">
    <div class="welcome">您好，欢迎登录山水物联官网后台管理系统！</div>
    <div class="titleH">便捷高效  互联互通 </div>
    <div class="copy">版权所有 © 辽宁山水通途物联智能科技有限公司</div>
    <div class="line" />
  </div>
  <!-- <ueditor-wrap initial-frame-width="800" initial-frame-height="600" :ueditor-val="ueditorVal" @ueditorMsg="ueditorMsg" /> -->
</template>

<script>
export default {
  name: 'Backhome',
  data() {
    return {
      msg: '后台管理',

      ueditorVal: ''
    }
  },
  methods: {

    ueditorMsg(val) {

    }
  }
}
</script>

<style scoped lang='scss'>
.backhome{
    position: relative;
    background: url("../../assets/img/index-img.jpg");
    height: 100vh;
    background-size: cover;
    .welcome{
      padding: 100px 0 0 80px;
      font-size: 18px;
      color:#414141;
    }
    .titleH{
      position: absolute;
      left: 100px;
      bottom: 140px;
      font-size: 46px;
      font-weight: bold;
      color: #0033a4;
      letter-spacing: 10px;

    }
    .copy{
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      line-height: 78px;
      text-align: center;
      font-size: 12px;
      color: #9ea6bb;
    }
    .line{
      height: 1px;
      background: #8793b7;
      opacity: .1;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 78px;
    }
}
</style>
